import type { EventType } from '@/types/generated';
import dayjs from 'dayjs';

export const Utils = {
  responsive_pie: {
    rules: [
      {
        condition: {
          maxWidth: 700
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            itemMarginTop: 3,
            itemMarginBottom: 3,
            maxHeight: 130
          }
        }
      }
    ]
  },
  humanFileSize: function (bytes, si) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  },
  formatCurrency: function (
    value = 0,
    notation: Intl.NumberFormatOptions['notation'] = 'compact',
    round = false
  ) {
    const formatter = new Intl.NumberFormat('en-US', {
      notation,
      style: 'currency',
      currency: 'EUR',
      compactDisplay: 'long'
    });

    return formatter.format(round ? Math.round(value) : value);
  },
  formatNumber: function (value = 0, compact = true, locale = 'en-US') {
    const formatter = new Intl.NumberFormat(locale, {
      notation: compact ? 'compact' : 'standard'
    });
    return formatter.format(value);
  },
  randomPassword: function (length = 12) {
    if (!window.crypto || !window.crypto.getRandomValues) {
      return Math.random().toString(36).slice(-length);
    }

    return window.crypto
      .getRandomValues(new BigUint64Array(4))
      .reduce<string>(
        (prev, curr, index) =>
          prev + (index % 2 ? curr.toString(36).toUpperCase() : curr.toString(36)),
        ''
      )
      .split('')
      .sort(() => 128 - window.crypto.getRandomValues(new Uint8Array(1))[0])
      .join('')
      .slice(0, length);
  },
  getFormData($form) {
    // @ts-ignore
    return $.deparam($form.serialize());
  },
  getFormDataReact($form: JQuery<HTMLFormElement>): Record<any, any> {
    const queryString = $form.serialize();
    const params = new URLSearchParams(queryString);
    const result = {};

    for (const [key, value] of params) {
      if (key.endsWith('[]')) {
        const cleanKey = key.slice(0, -2);
        if (!result[cleanKey]) {
          result[cleanKey] = [value];
        } else {
          result[cleanKey].push(value);
        }
      } else {
        result[key] = value;
      }
    }

    return result;
  },
  formatDate(date: dayjs.ConfigType, format = 'DD/MM/YYYY') {
    return dayjs(date).format(format);
  },
  auditLog(
    event_type: EventType,
    event_description: string | undefined = undefined,
    event_data: Record<any, any> | undefined = undefined
  ) {
    window.$.post('/logs/audit', {
      e: event_type,
      ed: event_description,
      d: event_data
    });
  },
  blobToJson(b: Blob | MediaSource) {
    const u = URL.createObjectURL(b);
    const x = new XMLHttpRequest();
    x.open('GET', u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return JSON.parse(x.responseText);
  },
  handleAjaxError(status: number) {
    toast(this.getStatusMessage(status, false), 'error');
  },
  getStatusMessage(status: number, html: boolean = true) {
    if (html) {
      if (status === 504) {
        return 'The action took too long to complete. You could optimize your filters. If the issue persists, please contact us through chat or at <a href="mailto:contact@hermix.com">contact@hermix.com</a>.';
      }
      return 'Something went wrong. If this issue persists, please contact us through chat or at <a href="mailto:contact@hermix.com">contact@hermix.com</a>.';
    }
    if (status === 504) {
      return 'The action took too long to complete. You could optimize your filters. If the issue persists, please contact us through chat or at contact@hermix.com.';
    }
    return 'Something went wrong. If this issue persists, please contact us through chat or at contact@hermix.com.';
  }
};

declare global {
  interface String {
    ucFirst(): string;
  }
}

if (!String.prototype.ucFirst) {
  String.prototype.ucFirst = function () {
    return this.toString().charAt(0).toUpperCase() + this.toString().slice(1).toLowerCase();
  };
}

window.Utils = Utils;
